import React from "react";
import { MetaTags } from "react-meta-tags";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

// MUI Component
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import LockIcon from "@mui/icons-material/Lock";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AddCardIcon from "@mui/icons-material/AddCard";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import Layout from "../../../Layout/Layout";
import CourseImg from "../../../assets/courses/alpha-foundation/alpha-foundation-course-at-rap-analytical.png";
import Course_Lab_Img from "../../../assets/courses/alpha-foundation/rap-analytical-lab-instruments-training.png";
import Instrument1 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-01.png";
import Instrument2 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-02.png";
import Instrument3 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-03.png";
import Instrument4 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-04.png";
import Instrument5 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-05.png";
import Instrument6 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-06.png";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

function Alpha_Foundation() {
  const doubleQuoteSymbol = (
    <svg
      width="70px"
      height="30px"
      viewBox="0 0 49 39"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: "rotate(180deg)",
        margin: "-40px 0px 20px",
      }}>
      <path d="M49,39 L49,21.2333333 L39.8211921,21.2333333 L39.8211921,18.9222222 C39.8211921,12.5666667 42.3465784,9.38888889 47.397351,9.38888889 L47.397351,9.38888889 L47.397351,0 C40.6953642,0 35.6203091,1.97407407 32.1721854,5.92222222 C28.7240618,9.87037037 27,14.9259259 27,21.0888889 C27,27.2518519 27.9713024,33.2222222 29.9139073,39 L29.9139073,39 L49,39 Z M22,39 L22,21.2333333 L12.8211921,21.2333333 L12.8211921,18.9222222 C12.8211921,12.5666667 15.3465784,9.38888889 20.397351,9.38888889 L20.397351,9.38888889 L20.397351,0 C13.6953642,0 8.62030905,1.97407407 5.17218543,5.92222222 C1.72406181,9.87037037 0,14.9259259 0,21.0888889 C0,27.2518519 0.971302428,33.2222222 2.91390728,39 L2.91390728,39 L22,39 Z"></path>
    </svg>
  );
  return (
    <>
      <MetaTags>
        <title>Regulatory Affairs | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>

      <Layout>
        <div className="container">
          <div className="course-content-wrapper">
            <div className="course-desc-content">
              <div className="course-entry-image-wrapper">
                <div className="entry-course-info text-center">
                  {/* <div className="course-instructor">
                    <AddCardIcon />
                    <span className="meta-label"> Price </span>
                    <span
                      className="meta-value"
                      style={{ fontSize: "22px", color: "#8cb13d" }}>
                      <CurrencyRupeeIcon />
                      12,500.00
                    </span>
                  </div> */}
                  <div className="course-instructor">
                    <AssignmentIcon />
                    <span className="meta-label"> Instructor </span>
                    <span className="meta-value">Team RAP</span>
                  </div>
                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Duration</span>
                    <span className="meta-value">30 days</span>
                  </div>
                  <div className="course-instructor">
                    <InsertDriveFileIcon />
                    <span className="meta-label">Schedule</span>
                    <span className="meta-value">2 hours</span>
                  </div>
                  <div className="course-instructor">
                    <AccountCircleIcon />
                    <span className="meta-label">Enrolled</span>
                    <span className="meta-value">86000 students</span>
                  </div>
                  <div className="course-instructor">
                    <GTranslateIcon />
                    <span className="meta-label">Language</span>
                    <span className="meta-value">English</span>
                  </div>

                  <div className="icons-tooltip text-center">
                    Share this course <ShareIcon sx={{ fontSize: "16px" }} />
                    <span className="icons-tooltiptext">
                      {" "}
                      <div className="icons-group">
                        <button
                          onClick={() =>
                            window.open("https://twitter.com/RAPAnalytical")
                          }>
                          <TwitterIcon />
                        </button>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/RAPAnalytical"
                            )
                          }>
                          <FacebookIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/rapanalytical/"
                            )
                          }>
                          <InstagramIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/in/rapanalytical/"
                            )
                          }>
                          <LinkedInIcon />
                        </IconButton>
                      </div>
                    </span>
                  </div>

                  <h4>
                    <strong>
                      <Link
                        to={"/foundation-course-registration"}
                        state={{
                          data: {
                            title: "Hands-on Training",
                            fee: "12,500",
                          },
                        }}>
                        Buy Now{" "}
                      </Link>
                    </strong>
                  </h4>
                </div>
                <div className="course-img">
                  <img src={CourseImg} alt="" />
                </div>
              </div>

              <Tab.Container defaultActiveKey="overview">
                <Nav variant="pills" className="course-info-tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">
                      <h4>OverView</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="curriculum">
                      <h4>Curriculum</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="insrtuctor">
                      <h4>Instructor</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="reviews">
                      <h4>Reviews</h4>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="course-desc">
                      <h3>
                        <strong>Introduction to Analytical Instruments:</strong>
                      </h3>

                      <p>
                        Overview of all different types of analytical
                        instruments commonly used in research and industry, such
                        as chromatography (HPLC, GC), spectroscopy (UV-Vis),
                        dissolution apparatus, disintegration apparatus,
                        friability tester and others.
                      </p>

                      <h3>
                        <strong>Instrument Operation:</strong>
                      </h3>
                      <p>
                        {" "}
                        Hands-on training on operating various analytical
                        instruments, including instrument setup, calibration,
                        SOPs, maintenance, and troubleshooting.
                      </p>

                      {/* <div className="instument-img-wrapper">
                        <div className="instrument-single-img">
                          <img src={Instrument1} alt="" />
                          <div className="instrument-single-img-name">HPLC</div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument2} alt="" />
                          <div className="instrument-single-img-name">
                            UV-VIS Spectrophotometer
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument3} alt="" />
                          <div className="instrument-single-img-name">
                            {" "}
                            Gas Chromatography
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument4} alt="" />
                          <div className="instrument-single-img-name">
                            Friability Apparatus
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument5} alt="" />
                          <div className="instrument-single-img-name">
                            Dissolution Apparatus
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument6} alt="" />
                          <div className="instrument-single-img-name">
                            Disintegration Apparatus
                          </div>
                        </div>
                      </div> */}

                      <h3>
                        <strong>Data Acquisition and Analysis:</strong>
                      </h3>

                      <p>
                        Instruction on data acquisition techniques and software
                        analysis tools associated with each instrument type.
                        This may include interpretation of spectra,
                        chromatograms, and statistics.
                      </p>
                      {/* 
                      <ul>
                        <li>Basic principles of all instruments</li>
                        <li>
                          The easy and logical ways to adjust experimental
                          variables to get desirable results
                        </li>
                        <li>
                          The best approach to undergo for various
                          chromatographic applications as well as industrial QC
                          & R&D practices.
                        </li>
                        <li>
                          Proven techniques for systematic problem-solving and
                          instrument maintenance.
                        </li>
                        <li>
                          The most effective time saving, money-saving
                          approaches to preventing common hardware problems and
                          method failures and much more.
                        </li>
                      </ul> */}

                      <div className="course-img">
                        <img src={Course_Lab_Img} alt="" />
                      </div>

                      <h3>
                        <strong>Sample Preparation Techniques:</strong>
                      </h3>

                      <p>
                        Techniques for sample preparation prior to analysis,
                        including calculations, dilutions and apparatus
                        handlings.
                      </p>
                      <h3>
                        <strong>Quality Control and Assurance:</strong>
                      </h3>
                      <p>
                        Overview of quality control procedures, calibration
                        standards, and quality assurance protocols to ensure
                        accuracy and precision in analytical measurements.
                      </p>

                      <h3>
                        <strong>Applications and Case Studies:</strong>
                      </h3>
                      <p>
                        Real-world applications of analytical techniques in
                        different industries such as pharmaceuticals,
                        environmental science, forensics, and materials science.
                        Case studies may illustrate how analytical instruments
                        are used to solve specific analytical problems.
                      </p>

                      <h3>
                        <strong>Practical Laboratory Exercises:</strong>
                      </h3>
                      <p>
                        Hands-on laboratory sessions where participants apply
                        theoretical knowledge by performing analytical
                        experiments, analyzing samples, and interpreting results
                        under the guidance of instructors.
                      </p>

                      <h3>
                        <strong>Certification:</strong>
                      </h3>
                      <p>
                        An online course terminal examination will be conducted
                        for assessment of the work done during the training and
                        certificate will be issued on successfully passing the
                        examination.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum">
                    <div className="course-desc">
                      <div className="curriculum-content">
                        <h5>Modules</h5>
                        <p>
                          {" "}
                          <i>
                            HPLC, GC, UV-VIS, Dissolution Application,
                            Disintegration Application, Friability Application
                          </i>{" "}
                        </p>

                        <ul>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Introduction
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Chromatographic Parameters
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Principles
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Working
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Instrumentation
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Data Analysis/Interpretation
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Quantitative and Qualitative Analysis
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Method Development
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Method Validation
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Troubleshooting
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Applications
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Calculations
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Practical Training
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="insrtuctor">
                    <div className="course-desc">
                      <div className="review-content">
                        <h4>Team RAP</h4>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="reviews">
                    <div className="review-content">
                      <div
                        class="card col-md-5 review-box m-2"
                        style={{ float: "left" }}>
                        <div class="card-body">
                          {doubleQuoteSymbol}

                          <p class="card-text">
                            The well resolved Laboratory with the clean and
                            proper hygienic condition. Proper handling of
                            instruments till having a prefectness with guidance
                            of supportive Analyst's and the most highly
                            experienced with having a lot of achievements, the
                            Head of Research centre Mr. Rohan Pawar Sir. I am
                            proud and lucky to be a part of RAP Analytical.
                            Thank you all of you for your valuable support
                          </p>
                          {/* <a href="#" class="card-link">Card link</a>
                            <a href="#" class="card-link">Another link</a> */}
                        </div>
                        <h5 class="name-css"> - Mayur Gadhe</h5>
                      </div>

                      <div
                        class="card col-md-5 review-box  m-2"
                        style={{ float: "left" }}>
                        <div class="card-body">
                          {doubleQuoteSymbol}

                          <p class="card-text">
                            I did my training in this center. Excellent teaching
                            staff. They help me to get good practical training
                            and also gave me deep knowledge about instruments.
                            Briefly it is best course for enhancing skills and
                            practical abilities. It was very nice experience for
                            me in this course.
                          </p>
                        </div>
                        <h5 class="name-css"> - Akshay Dukre</h5>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Alpha_Foundation;
