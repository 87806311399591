import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/Logo/rap-analytical-logo.png";

// MUI Component
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import FacebookIcon from "@mui/icons-material/Facebook";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

import MobileNavBar from "../mobile_view/MobileNavBar";

function NavBar() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const isActive = (path) => {
    return window.location.pathname === path;
  };

  // const handleNavbar = () => {
  //   let navbar = document.querySelector(".nav");
  //   navbar.classList.toggle("nav-menu-list");
  // };

  return (
    <div className="container-fluid navbar">
      <div className="logo">
      <Link className={`${isActive('/') ? 'active1' : ''}`} to="/">
          <img src={Logo} alt="" width="150px" />
      </Link>
      </div>

      <div className="nav">
        <ul className="nav-ul">
          <li className="cool-link active1">
            <Link className={`${isActive('/') ? 'active1' : ''}`} to="/">Home</Link>
          </li>
          <li className="dropdown cool-link">
            <a className={`${isActive('/about/rap_analytical') || isActive('/about/director_message') ? 'active1' : ''}`}>
              About <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
              <div className="dropdown-content">
                <Link  to="/about/rap_analytical">About RAP Analytical</Link>
                <Link  to="/about/director_message">Directors Message</Link>
              </div>
            </a>
          </li>
          <li className="dropdown cool-link">
            <Link className={`${isActive('/courses') || 
                                isActive('/courses/foundation' ) || 
                                isActive('/courses/foundation/alpha-foundation' ) ||
                                isActive('/courses/regulatory_course' ) ||
                                isActive('/courses/marcket_research' ) ||
                                isActive('/courses/medical_coding' ) ||
                                isActive('/courses/pharmcovigilance' )
                                ? 'active1' : ''}`} to="/courses">
              Courses <KeyboardArrowDownIcon sx={{ fontSize: "17px" }} />
            </Link>
            <div className="dropdown-content">
            <Link to="/courses/foundation/alpha-foundation">
                    {" "}
                    Hands-on Training
                  </Link>
                {/* <div className="nested-dropdown-content">
                  <Link to="/courses/foundation/alpha-foundation">
                    {" "}
                    Hands-on Training
                  </Link>
                </div> */}
              
              <Link to="/courses/medical_coding">Medical Coding</Link>
              
              <Link to="/courses/pharmcovigilance">PharmCovigilance</Link>
              {/* <Link className="nested-dropdown" to="/courses/regulatory_course">
                Regulatory Course
              </Link>

              <Link to="/courses/marcket_research">Marcket Resaerch</Link> */}
             
            </div>
          </li>
          <li className="cool-link">
            <Link className={`${isActive('/services') ? 'active1' : ''}`} to="/services">Services</Link>
          </li>
          <li className="cool-link">
            <Link className={`${isActive('/testimonial') ? 'active1' : ''}`} to="/testimonial">Testimonials</Link>
          </li>
          <li className="cool-link">
            <Link className={`${isActive('/gallary') ? 'active1' : ''}`} to="/gallary">Gallery</Link>
          </li>
          <li className="cool-link">
            <Link className={`${isActive('/contact') ? 'active1' : ''}`} to="/contact">Contact</Link>
          </li>
          <li className="cool-link">
            <Link className={`${isActive('/event') ? 'active1' : ''}`} to="/event">Events</Link>
          </li>
        </ul>

        <div className="header-icons-group">
          <i onClick={() => window.open("https://twitter.com/RAPAnalytical")}>
            <FontAwesomeIcon icon={faTwitter} />
          </i>

          <i
            onClick={() =>
              window.open("https://www.facebook.com/RAPAnalytical")
            }>
            <FontAwesomeIcon icon={faFacebookF} />
          </i>
          <i
            onClick={() =>
              window.open("https://www.instagram.com/rapanalytical/")
            }>
            <FontAwesomeIcon icon={faInstagram} />
          </i>
          <i
            onClick={() =>
              window.open("https://www.linkedin.com/in/rapanalytical/")
            }>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </i>
          <i>
            <div className="responsive-navbar">
              <IconButton onClick={toggleDrawer("right", true)}>
                <MenuIcon sx={{ color: "black", fontSize: "21px" }} />
              </IconButton>
            </div>
          </i>
        </div>
      </div>

      {/* <Button onClick={toggleDrawer("right", true)}>Right</Button> */}

      <MobileNavBar toggleDrawer={toggleDrawer} state={state} />
    </div>
  );
}

export default NavBar;
