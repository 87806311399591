import React, { useRef, useState } from "react";

// import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
// import { useEffect } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const doubleQuoteSymbol = (
  <svg
    width="70px"
    height="30px"
    viewBox="0 0 49 39"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      transform: "rotate(180deg)",
      margin: "-60px 0px 20px",
    }}>
    <path d="M49,39 L49,21.2333333 L39.8211921,21.2333333 L39.8211921,18.9222222 C39.8211921,12.5666667 42.3465784,9.38888889 47.397351,9.38888889 L47.397351,9.38888889 L47.397351,0 C40.6953642,0 35.6203091,1.97407407 32.1721854,5.92222222 C28.7240618,9.87037037 27,14.9259259 27,21.0888889 C27,27.2518519 27.9713024,33.2222222 29.9139073,39 L29.9139073,39 L49,39 Z M22,39 L22,21.2333333 L12.8211921,21.2333333 L12.8211921,18.9222222 C12.8211921,12.5666667 15.3465784,9.38888889 20.397351,9.38888889 L20.397351,9.38888889 L20.397351,0 C13.6953642,0 8.62030905,1.97407407 5.17218543,5.92222222 C1.72406181,9.87037037 0,14.9259259 0,21.0888889 C0,27.2518519 0.971302428,33.2222222 2.91390728,39 L2.91390728,39 L22,39 Z"></path>
  </svg>
);

// const data = [
//   <div className="single-slider-content">
//     {doubleQuoteSymbol}
//     <p>
//       It was a great learning experience at RAP Training Center. As also I am
//       extremely obliged to thank Rohan Sir who took extra efforts for helping me
//       with my placement at Glaxo Pharmaceuticals (GSK) in Research and
//       Development at the post of Research Associate. Without his training it
//       would have been very difficult for me to handle software at GSK. The
//       lectures conducted by him and the notes provided by him are extremely
//       useful for cracking placement interviews at pharmaceutical companies. It
//       gives me immense pleasure to say that Sir gave me extra time for giving
//       troubleshooting tips for HPLC AND GC. I appreciate his knowledge and the
//       concept of analytical training at such an economical price.
//     </p>

//     <h5>Malti Laxman Khatri</h5>
//     <h6>/ Research Associate, R&D, GlaxoSmithKline, Nashik</h6>
//   </div>,

//   <div className="single-slider-content">
//     {doubleQuoteSymbol}
//     <p>
//       Practical knowledge of Analytical techniques such as HPLC, GC, UV is very
//       important when you wish to work in an industry. RAP Analytical has
//       provided a best platform for those who intend to have the practical
//       knowledge of these techniques. In the 21 days course , I learned how to
//       handle all the instruments , troubleshooting & many concepts got cleared.
//       I got an awesome experience here & it will help me a lot in the future.
//     </p>

//     <h5>Sayali Khairnar</h5>
//     <h6>/ M.Sc. (Biotechnology), NDMVP College, Nashik</h6>
//   </div>,

//   <div className="single-slider-content">
//     {doubleQuoteSymbol}
//     <p>
//       Ever-since I have completed RAP analytical instrument handling & training
//       I got more confident about HPLC, GC & UV spectroscopy based on thorough
//       knowledge that provided in class by Mr. Rohan Pawar sir. Industrial
//       oriented skillful handling of analytical instruments with more practice is
//       more special I would say about this institute.
//     </p>

//     <h5>Shambala Patil</h5>
//     <h6>/ M.Pharm (Pharmacology), COP, Nashik</h6>
//   </div>,

//   <div className="single-slider-content">
//     {doubleQuoteSymbol}
//     <p>
//       Previously, I didn't have practical knowledge about HPLC, GC & UV but when
//       I went through the course modules, I felt that doing this course on
//       analytical instruments would help me to get hands-on training and help me
//       to make a transition from a student to an industry ready person in a much
//       smoother way. The course gave me the necessary technical skills to make
//       this transition. Whatever was there in my mind at the beginning of this
//       course, whatever objectives I have had, they all have been met. The course
//       structure was very well formed. They covered almost everything required
//       for an industry to function & as a fresher to know about it. It is a very
//       nice course to make a fresher ready for industry."
//     </p>

//     <h5>Manohar Sonawane</h5>
//     <h6>/ M.Sc. (Biotechnology), NDMVP College, Nashik</h6>
//   </div>,
// ];

function HomeMiniSlider() {
  const settings = {
    className: "center",
    centerMode: true,
    // centerPadding: "120px",
    slidesToShow: 3,
    dots: true,
    infinite: true,
     autoplay: true,
     autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          arrows: true,
          centerMode: true,
          // centerPadding: "40px",
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          centerMode: true,
          // centerPadding: "40px",
          slidesToShow: 1,
        },
      },
    ],
  };

  const sliderRef = useRef(null);

  // const handleGoToSlide = (index) => {
  //   console.log(index);
  //   sliderRef.current.slickGoTo(index);

  //   console.log(sliderRef.current.slickGoTo(index));
  // };

  return (
    <div className="home-mini-slider">
      <Slider {...settings} ref={sliderRef}>
        <div className="single-slider-content">
          {doubleQuoteSymbol}

          <p>
            It was a great learning experience at RAP Training Center. As also I
            am extremely obliged to thank Rohan Sir who took extra efforts for
            helping me with my placement at Glaxo Pharmaceuticals (GSK) in
            Research and Development at the post of Research Associate. Without
            his training it would have been very difficult for me to handle
            software at GSK. The lectures conducted by him and the notes
            provided by him are extremely useful for cracking placement
            interviews at pharmaceutical companies. It gives me immense pleasure
            to say that Sir gave me extra time for giving troubleshooting tips
            for HPLC AND GC. I appreciate his knowledge and the concept of
            analytical training at such an economical price.
          </p>

          <h5>Malti Laxman Khatri</h5>
          <h6>/ Research Associate, R&D, GlaxoSmithKline, Nashik</h6>
        </div>

        <div className="single-slider-content">
          {doubleQuoteSymbol}

          <p>
            Practical knowledge of Analytical techniques such as HPLC, GC, UV is
            very important when you wish to work in an industry. RAP Analytical
            has provided a best platform for those who intend to have the
            practical knowledge of these techniques. In the 21 days course , I
            learned how to handle all the instruments , troubleshooting & many
            concepts got cleared. I got an awesome experience here & it will
            help me a lot in the future.
          </p>

          <h5>Sayali Khairnar</h5>
          <h6>/ M.Sc. (Biotechnology), NDMVP College, Nashik</h6>
        </div>

        <div className="single-slider-content">
          {doubleQuoteSymbol}

          <p>
            Ever-since I have completed RAP analytical instrument handling &
            training I got more confident about HPLC, GC & UV spectroscopy based
            on thorough knowledge that provided in class by Mr. Rohan Pawar sir.
            Industrial oriented skillful handling of analytical instruments with
            more practice is more special I would say about this institute.
          </p>

          <h5>Shambala Patil</h5>
          <h6>/ M.Pharm (Pharmacology), COP, Nashik</h6>
        </div>

        <div className="single-slider-content">
          {doubleQuoteSymbol}

          <p>
            Previously, I didn't have practical knowledge about HPLC, GC & UV
            but when I went through the course modules, I felt that doing this
            course on analytical instruments would help me to get hands-on
            training and help me to make a transition from a student to an
            industry ready person in a much smoother way. The course gave me the
            necessary technical skills to make this transition. Whatever was
            there in my mind at the beginning of this course, whatever
            objectives I have had, they all have been met. The course structure
            was very well formed. They covered almost everything required for an
            industry to function & as a fresher to know about it. It is a very
            nice course to make a fresher ready for industry."
          </p>

          <h5>Manohar Sonawane</h5>
          <h6>/ M.Sc. (Biotechnology), NDMVP College, Nashik</h6>
        </div>
      </Slider>

      {/* <button onClick={() => handleGoToSlide(2)}>Go to slide 2</button> */}
    </div>
  );
}

export default HomeMiniSlider;

{
  /* <div className="slider-content">
<Swiper {...params}>
  <div className="single-slider-content">
    {doubleQuoteSymbol}

    <p>
      It was a great learning experience at RAP Training Center. As
      also I am extremely obliged to thank Rohan Sir who took extra
      efforts for helping me with my placement at Glaxo
      Pharmaceuticals (GSK) in Research and Development at the post of
      Research Associate. Without his training it would have been very
      difficult for me to handle software at GSK. The lectures
      conducted by him and the notes provided by him are extremely
      useful for cracking placement interviews at pharmaceutical
      companies. It gives me immense pleasure to say that Sir gave me
      extra time for giving troubleshooting tips for HPLC AND GC. I
      appreciate his knowledge and the concept of analytical training
      at such an economical price.
    </p>

    <h5>Malti Laxman Khatri</h5>
    <h6>/ Research Associate, R&D, GlaxoSmithKline, Nashik</h6>
  </div>

  <div className="single-slider-content">
    {doubleQuoteSymbol}

    <p>
      Practical knowledge of Analytical techniques such as HPLC, GC,
      UV is very important when you wish to work in an industry. RAP
      Analytical has provided a best platform for those who intend to
      have the practical knowledge of these techniques. In the 21 days
      course , I learned how to handle all the instruments ,
      troubleshooting & many concepts got cleared. I got an awesome
      experience here & it will help me a lot in the future.
    </p>

    <h5>Sayali Khairnar</h5>
    <h6>/ M.Sc. (Biotechnology), NDMVP College, Nashik</h6>
  </div>

  <div className="single-slider-content">
    {doubleQuoteSymbol}

    <p>
      Ever-since I have completed RAP analytical instrument handling &
      training I got more confident about HPLC, GC & UV spectroscopy
      based on thorough knowledge that provided in class by Mr. Rohan
      Pawar sir. Industrial oriented skillful handling of analytical
      instruments with more practice is more special I would say about
      this institute.
    </p>

    <h5>Shambala Patil</h5>
    <h6>/ M.Pharm (Pharmacology), COP, Nashik</h6>
  </div>

  <div className="single-slider-content">
    {doubleQuoteSymbol}

    <p>
      Previously, I didn't have practical knowledge about HPLC, GC &
      UV but when I went through the course modules, I felt that doing
      this course on analytical instruments would help me to get
      hands-on training and help me to make a transition from a
      student to an industry ready person in a much smoother way. The
      course gave me the necessary technical skills to make this
      transition. Whatever was there in my mind at the beginning of
      this course, whatever objectives I have had, they all have been
      met. The course structure was very well formed. They covered
      almost everything required for an industry to function & as a
      fresher to know about it. It is a very nice course to make a
      fresher ready for industry."
    </p>

    <h5>Manohar Sonawane</h5>
    <h6>/ M.Sc. (Biotechnology), NDMVP College, Nashik</h6>
  </div>
</Swiper>
</div> */
}
