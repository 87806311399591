import React from "react";
import { MetaTags } from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import Layout from "../Layout/Layout";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import EventImg from "../assets/events/workshops-research-methodology.png";
import { Link } from "react-router-dom";

function RefundPolicy() {
  return (
    <>
      <MetaTags>
        <title>Refund Policy | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/refundPolicy"}>Refund Policy:</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Refund Policy" />

        <div className="container">
          <div className="event-wrapper">
            <div className="event-card">
              <div >
                <h5> Refund Policy: </h5>
                

                <ol>
                  <li>Fees once paid is not refundable for any reason.</li>
                  <li>The candidate who cancels the admission before 1 week of course commencement, the refund 
will be 100% of total fees. (Applicable ONLY for hands on training course)</li>
                  <li>The candidate who cancels the admission before 3 days or less than 3 days shall be refunded 
with only 50% of total fees. (Applicable on for Hands on training course)</li>
                  <li>Once the course is commenced if the candidate wishes to cancel the admission , no refund will be applicable</li>
                  <li> Complete fees should be paid on or before the first day of commencement of course.</li>
                  <li>Cheques will be not accepted on the day of commencement of course.</li>
                  <li>Cheque payment should be cleared before the commencement of course.</li>
                </ol>
                

                
              </div>
              
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default RefundPolicy;
