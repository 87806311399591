import React from "react";
import Layout from "../Layout/Layout";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";

import BreadCrumb from "../Breadcrumb/BreadCrumb";
import Course_Img1 from "../assets/courses/all-courses/alpha-foundation-course-at-rap-analytical.png";
import { Link } from "react-router-dom";
import Course_Img2 from "../assets/courses/all-courses/alpha-foundation-course-rap-analytical-institute.png";
import Course_Img3 from "../assets/courses/all-courses/market-research-at-rap-analytical.png";
import Course_Img4 from "../assets/courses/all-courses/medical-coding-course-rap-analytical.png";
import Course_Img5 from "../assets/courses/all-courses/pharmacovigilance-course-rap-analytical.png";
import Course_Img6 from "../assets/courses/all-courses/regulatory-affairs-rap-analytical.png";

const courseData = [
  {
    image: Course_Img1,
    // price: "12,500",
    name: "Hands-on Training",
    path: "/courses/foundation/alpha-foundation",
  },
  // {
  //   image: Course_Img2,
  //   price: "10,000.00",
  //   name: "Beta Foundation Course",
  //   path: "/courses/foundation/alpha-foundation",
  // },
  // {
  //   image: Course_Img3,
  //   price: "5,000",
  //   name: "Market Research",
  //   path: "/courses/marcket_research",
  // },
  {
    image: Course_Img4,
    // price: "25,000",
    name: "Medical Coding",
    path: "/courses/medical_coding",
    // sub_price: "27,000",
    // paid_fee: "13,500",
  },
  {
    image: Course_Img5,
    // price: "18,000",
    name: "Pharmacovigilance",
    path: "/courses/pharmcovigilance",
    // sub_price: "20,000",
    // paid_fee: "10,000",
  },
  // {
  //   image: Course_Img6,
  //   price: "00",
  //   name: "Regulatory Affairs",
  //   path: "/courses/regulatory_course",
  // },
];

function Courses() {
  return (
    <>
      <MetaTags>
        <title>All Courses | RAP</title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>All Courses</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="All Courses" />
        <div className="container">
          <div className="all-courses-content">
            {courseData.map((val, key) => {
              return (
                <div className="single-course-details" key={key}>
                  <Link to={val.path}>
                    <div className="course-img">
                      <img src={val.image} alt="" />
                    </div>
                  </Link>
                  <div className="course-info">
                    {/* <div className="course-price">₹{val.price}</div> */}
                    <div className="course-title">
                      {" "}
                      <strong>
                        <Link to={val.path}> {val.name} </Link>
                      </strong>
                      <h4>
                        <strong>
                          <Link
                            to={"/foundation-course-registration"}
                            state={{
                              data: {
                                title: val.name,
                                // fee: val.price,
                                // sub_fee: val.sub_price,
                                // paid_fee: val.paid_fee,
                              },
                            }}>
                            Buy Now
                          </Link>
                        </strong>
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Courses;
