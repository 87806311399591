import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";
// import { Carousel } from "react-bootstrap";
import axios from "axios";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ModalImage from "react-modal-image";
function Gallary() {
  const path = "https://www.rapanalytical.com/admin/uploads/";
  
  const [imgArray, setImgArray] = useState([]);
  useEffect(() => {
   
    axios
      .get("https://rapanalytical.com/webservice/gallery.php")
      .then((response) => {
        setImgArray(response.data.product_master);
      })
      .catch((error) => {
        console.log("Error: ", error);
      });
  }, []);
  //console.log("imgArray", imgArray);
  const handleImageClick = (imageUrl) => {
    console.log("Image clicked:", imageUrl);
   // setSelectedImage(imageUrl);
   // setOpen(true);
  };
  return (
    <>
      <MetaTags>
        <title>Gallery | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/gallery"}>Events</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Gallery" />
        <div className="container">
          <div className="gallary-wrapper">
            <h5>
              From the House of <strong>RAP Analytical</strong>{" "}
            </h5>
            <h2>Center and Events Gallery</h2>
            {imgArray &&
              imgArray.map((val) => {
                return (
                  <>
                    {val.gal_id % 2 == 0 ? (
                      <div className="gallary-single-row">
                        <div className="single-image-wrapper">
                          <h4>{val.gal_title}</h4>
                          <div className="single-image">
                            <img src={val.gal_img_first} alt="" width="90%"  onClick={() => handleImageClick(val.gal_img_second)} />
                          </div>
                        </div>

                        <div className="slider-images-wrapper">
                          <h1>{val.gal_date}</h1>
                          <p>{val.gal_desc}</p>
                          <div className="slider-images">
                            <Carousel
                              showThumbs={true}
                              showIndicators={false}
                              showStatus={false}
                              infiniteLoop={true}
                              autoPlay={true}
                              interval={3000}
                              transitionTime={500}
                              stopOnHover={false}
                              emulateTouch={false}
                              swipeable={true}
                              dynamicHeight={true}
                              centerMode={true}
                              showArrows={true}
                               //renderThumbs={renderThumbs}
                              // style={getCarouselStyle()}
                            >
                              {val.gal_img_second !== path && (
                            
                           
                              <img
                                  src={val.gal_img_second}
                                  alt={val.gal_img_second}
                                  width="90%"
                                  onClick={() => handleImageClick(val.gal_img_second)}
                              //    248775080
                                />
                                
                              )}

                              {val.gal_img_third !== path && (
                                <img
                                  src={val.gal_img_third}
                                  alt={val.gal_img_third}
                                  width="90%"
                                  onClick={() => handleImageClick(val.gal_img_second)}
                                />
                                
                              )}
                              {val.gal_img_fourth !== path && (
                                <img
                                  src={val.gal_img_fourth}
                                  alt={val.gal_img_fourth}
                                  width="90%"
                                  onClick={() => handleImageClick(val.gal_img_second)}
                                />
                              )}
                              <>
                                {val.gal_img_fifth !== path && (
                                  <img
                                    src={val.gal_img_fifth}
                                    alt={val.gal_img_fifth}
                                    width="90%"
                                    onClick={() => handleImageClick(val.gal_img_second)}
                                  />
                                )}
                              </>
                            </Carousel>

                           
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="left-gallary-single-row">
                        <div className="left-single-image-wrapper">
                          <h4>{val.gal_title}</h4>
                          <div className="left-single-image">
                            <img src={val.gal_img_first} alt="" />
                          </div>
                        </div>
                        <div className="left-slider-images-wrapper">
                          <h1>{val.gal_date}</h1>
                          <p>{val.gal_desc}</p>
                          <div className="left-slider-images">
                            <Carousel
                              showThumbs={true}
                              showIndicators={false}
                              showStatus={false}
                              infiniteLoop={true}
                              autoPlay={true}
                              interval={3000}
                              transitionTime={500}
                              stopOnHover={false}
                              emulateTouch={false}
                              swipeable={true}
                              dynamicHeight={true}
                              centerMode={true}
                              showArrows={true}>
                              {val.gal_img_second !== path && (
                                <img
                                  src={val.gal_img_second}
                                  alt={val.gal_img_second}
                                  width="90%"
                                  onClick={() => handleImageClick(val.gal_img_second)}
                                />
                              )}

                              {val.gal_img_third !== path && (
                                <img
                                  src={val.gal_img_third}
                                  alt={val.gal_img_third}
                                  width="90%"
                                  onClick={() => handleImageClick(val.gal_img_second)}
                                />
                              )}
                              {val.gal_img_fourth !== path && (
                                <img
                                  src={val.gal_img_fourth}
                                  alt={val.gal_img_fourth}
                                  width="90%"
                                  onClick={() => handleImageClick(val.gal_img_second)}
                                />
                              )}
                              <>
                                {val.gal_img_fifth !== path && (
                                  <img
                                    src={val.gal_img_fifth}
                                    alt={val.gal_img_fifth}
                                    width="90%"
                                    onClick={() => handleImageClick(val.gal_img_second)}
                                  />
                                )}
                              </>
                            </Carousel>
                          </div>
                        </div>
                      </div>
                    )}
                   
                  </>
                );
              })}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Gallary;
