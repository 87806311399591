import React, { Fragment } from "react";
import { MetaTags } from "react-meta-tags";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import Layout from "../Layout/Layout";

import Avatar1 from "../assets/testimonial/testimonial-avata-02.png";
import Avatar2 from "../assets/testimonial/testimonial-avata-04.png";
import Nikita_Wagh from "../assets/testimonial/Nikita-Wagh.png";
import Atul_Pund from "../assets/testimonial/Atul-Pund.png";
import Akshay_Patil from "../assets/testimonial/Akshay-Patil.png";
import mansi_jiwankar from "../assets/testimonial/mansi-jiwankar.png";
import pranjali_davda from "../assets/testimonial/pranjali-davda.png";

function Testimonial() {
  return (
    <>
      <MetaTags>
        <title>Testimonial | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>Testimonial</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Testimonial" />

        <div className="container">
          <div className="testimonial-content-wrapper">
          <div className="text-content mt-5">
              <h5>Learn from the Industry Experts</h5>
              <h3>
                Our Workshop
                <mark>Testimonials</mark>!
              </h3>
            </div>

            <div className="testimonial-profiles">
              <div className="profile-card">
                <div className="profile-img">
                  <img src={Akshay_Patil} alt="" />
                  <div className="text-content">
                    <h6>Akshay Patil</h6>
                    <span>
                      M. Pharrm, Bombay College of Pharmacy (BCP), Mumbai
                    </span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    It was so helpful for me to understand novel job
                    opportunities. Speakers were really full of knowledge &
                    enthusiastic which put feather in our hat of knowledge.
                    Everyone please definitely attend this workshop that will be
                    really helpful for you all!
                  </p>
                </div>
              </div>

              <div className="profile-card">
                <div className="profile-img">
                  <img src={Atul_Pund} alt="" />
                  <div className="text-content">
                    <h6>Atul Pund</h6>
                    <span>M. Pharrm, Blue Cross Laboratories, Nashik. </span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    Before registration I had lots of questions in my mind, like
                    whether it will possible to learn things in just 2 days and
                    many more, still I registered! It was just an amazing
                    platform & I learnt many things. It was a good experience
                    and It helped me during my M.Pharm research, also I learnt
                    about software operating which helped me during job search.
                    I won't request you to attend it, rather I would force you
                    all to attend the workshop.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-content">
              <h5>equip yourself with the power of analytical education</h5>
              <h3>
                Learn the secrets of
                <mark>Technical Career Success</mark>,
              </h3>
              <h3>
                and place the right
                <mark>key</mark>
                in your
                <mark>career keyhole</mark>!
              </h3>
            </div>

            <div className="testimonial-profiles">

              
            <div className="profile-card">
                <div className="profile-img">
                  <img src={Nikita_Wagh} alt="" />
                  <div className="text-content">
                    <h6>Nikita Wagh</h6>
                    <span>M. Pharrm in QA, PRES College of Pharmacy, Loni</span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    I feel so glad to share and celebrate with you that I have
                    completed my post graduation project with "O" (outstanding)
                    grade. And Second main reason to write this testimonial is
                    This Journey would have not been complete and taken such a
                    mesmerizing end without your assertive support, edifying
                    experience and emphasis of your faith on me! <br />
                    For me you are equally titleholders of this movement!
                    Special Thanks to Rohan Sir and Dear Shivani.
                  </p>
                </div>
            </div>

            <div className="profile-card">
                <div className="profile-img">
                  <img src={mansi_jiwankar} alt="" />
                  <div className="text-content">
                    <h6>Mansi Jiwankar</h6>
                    <span>PhD Student</span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                  I would like to thank RAP Analytical Research and Training Center, 
                  Nashik to help me out with my PhD project work (Animal study and 
                  Bioanalytical Method development). The research work completed within
                   promised time with good results. During my research work Mr. Rohan Pawar
                    and his team has supported me to clear my understandings and doubt related 
                    to work. It was a knowledge enriching journey with RAP Analytical Research 
                    and Training Center.
                  </p>
                </div>
            </div>
            
            <div className="profile-card">
                <div className="profile-img">
                  <img src={pranjali_davda} alt="" />
                  <div className="text-content">
                    <h6>Pranjali Davda</h6>
                    <span>Chemist QC Analytical Apotex Pharma, Toronto, Canada</span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                  I came to know about RAP from one of my friends.I'm a Master's in Botany 
                  and I decided to take the one month training course of instrumentation at
                   RAP. It is kind of surprising since I'm not from the pharma or chemistry
                    background. I even started a week late in my batch. Rohan sir and his 
                    team were very kind to let me join when he saw that I really really 
                    wanted to learn this and I would give in all the efforts. The training 
                    was provided by his team which was amazing. They trained us perfectly with
                     the practical working of the instrumentation in theory and then by
                      performing. I appreciate how we were allowed to actually use the 
                      instruments rather than just see it which gave us more confidence.
                       Sir himself came around to check and took a few lectures for us.
                        He might be strict but he's the man you need to learn from because
                         his knowledge and particularity is what I am able to see at work
                          now when I have a job. Everything that he said back then makes
                           sense now.I would totally recommend learning from RAP. Being a 
                           botanist, I can proudly say that today I cracked an interview in
                            one of the big shot pharmaceutical company in Canada only with 
                            the knowledge, notes and guidance sir and his team gave us. 
                            Trust me and trust the team, it'll be worth it more than anything 
                            you learnt so far.
                  </p>
                </div>
            </div>

              <div className="profile-card">
                <div className="profile-img">
                  <img src={Avatar1} alt="" />
                  <div className="text-content">
                    <h6>Malti Laxman Khatri</h6>
                    <span>
                      / Research Associate, R&D, GlaxoSmithKline, Nashik
                    </span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    It was a great learning experience at RAP Training Center.
                    As also I am extremely obliged to thank Rohan Sir who took
                    extra efforts for helping me with my placement at Glaxo
                    Pharmaceuticals (GSK) in Research and Development at the
                    post of Research Associate. Without his training it would
                    have been very difficult for me to handle software at GSK.
                    The lectures conducted by him and the notes provided by him
                    are extremely useful for cracking placement interviews at
                    pharmaceutical companies. It gives me immense pleasure to
                    say that Sir gave me extra time for giving troubleshooting
                    tips for HPLC AND GC. I appreciate his knowledge and the
                    concept of analytical training at such an economical price.{" "}
                  </p>
                </div>
              </div>

              <div className="profile-card">
                <div className="profile-img">
                  <img src={Avatar2} alt="" />
                  <div className="text-content">
                    <h6>Manohar Sonawane</h6>
                    <span>/ M.Sc. (Biotechnology), NDMVP College, Nashik</span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    Previously, I didn't have practical knowledge about HPLC, GC
                    & UV but when I went through the course modules, I felt that
                    doing this course on analytical instruments would help me to
                    get hands-on training and help me to make a transition from
                    a student to an industry ready person in a much smoother
                    way. The course gave me the necessary technical skills to
                    make this transition. Whatever was there in my mind at the
                    beginning of this course, whatever objectives I have had,
                    they all have been met. The course structure was very well
                    formed. They covered almost everything required for an
                    industry to function & as a fresher to know about it. It is
                    a very nice course to make a fresher ready for industry."{" "}
                  </p>
                </div>
              </div>

              <div className="profile-card">
                <div className="profile-img">
                  <img src={Avatar1} alt="" />
                  <div className="text-content">
                    <h6>Shambala Patil</h6>
                    <span>/ M.Pharm (Pharmacology), COP, Nashik</span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    Ever-since I have completed RAP analytical instrument
                    handling & training I got more confident about HPLC, GC & UV
                    spectroscopy based on thorough knowledge that provided in
                    class by Mr. Rohan Pawar sir. Industrial oriented skillful
                    handling of analytical instruments with more practice is
                    more special I would say about this institute.
                  </p>
                </div>
              </div>

              <div className="profile-card">
                <div className="profile-img">
                  <img src={Avatar1} alt="" />
                  <div className="text-content">
                    <h6>Sayali Khairnar</h6>
                    <span>/ M.Sc. (Biotechnology), NDMVP College, Nashik</span>
                  </div>
                </div>
                <div className="profile-content">
                  <p>
                    Practical knowledge of Analytical techniques such as HPLC,
                    GC, UV is very important when you wish to work in an
                    industry. RAP Analytical has provided a best platform for
                    those who intend to have the practical knowledge of these
                    techniques. In the 21 days course , I learned how to handle
                    all the instruments , troubleshooting & many concepts got
                    cleared. I got an awesome experience here & it will help me
                    a lot in the future.
                  </p>
                </div>
              </div>

            </div>

           
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Testimonial;
