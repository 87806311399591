import React from "react";
import Layout from "../../Layout/Layout";
import { MetaTags } from "react-meta-tags";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

// MUI Component
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import LockIcon from "@mui/icons-material/Lock";
import CourseImg from "../../assets/courses/medical-coding/medical-coding-course-rap-analytical.png";

import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

// import react-custom-share components

function Medical_Coding() {
  const currentUrl = window.location.href;

  const handleShare = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
    window.open(url, "_blank");
  };

  console.log(currentUrl);
  return (
    <>
      <MetaTags>
        <title>Medical Coding | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>

      <Layout>
        <div className="container">
          <div className="course-content-wrapper">
            <div className="course-entry-image-wrapper">
              <div className="entry-course-info text-center">
                <div className="course-instructor">
                  <AssignmentIcon />
                  <strong> Instructor </strong>
                  <div className="meta-value">Team RAP</div>
                </div>

                <div className="course-instructor">
                  <WatchLaterIcon />
                  <strong className="meta-label">Duration</strong>
                  <div className="meta-value">3 Months</div>
                </div>

                <div className="course-instructor">
                  <WatchLaterIcon />
                  <strong className="meta-label">Weekend Batches</strong>
                  <div className="meta-value">
                    All 4 Sundays + First and Last Saturday
                  </div>
                </div>

                <div className="course-instructor">
                  <WatchLaterIcon />
                  <strong className="meta-label">Time</strong>
                  <div className="meta-value">8am to 12pm</div>
                </div>

                <div className="course-instructor">
                  <AutoModeIcon />
                  <strong className="meta-label">Mode</strong>
                  <div className="meta-value">Online(Zoom Platform)</div>
                </div>

                <div className="course-instructor extra-text-content">
                  <WorkspacePremiumIcon />
                  <strong className="meta-label">Relevant for</strong>
                  <div className="meta-value extra-text">
                    Life Science Students (B.Sc. & M. Sc.), B. Pharm, M. Pharm,
                    D. Pharm, BAMS, BHMS,{" "}
                  </div>
                </div>

                <div className="course-instructor">
                  <GTranslateIcon />
                  <strong className="meta-label">Language</strong>
                  <div className="meta-value">English</div>
                </div>

                {/* <div className="course-instructor">
                  <CurrencyRupeeIcon />
                  <strong className="meta-label">Fees</strong>
                  <div className="meta-value">
                    : Rs. 25000 (If paid in one stroke)
                  </div>
                </div> */}

                {/* <div className="course-instructor">
                  <CurrencyRupeeIcon />
                  <strong className="meta-label">
                    Rs. 27000 (If paid in two slabs)
                  </strong>
                </div> */}

                <div className="icons-tooltip text-center">
                  Share this course <ShareIcon sx={{ fontSize: "16px" }} />
                  <div className="icons-tooltiptext">
                    {" "}
                    <div className="icons-group">
                      <button
                        onClick={() =>
                          window.open("https://twitter.com/RAPAnalytical")
                        }>
                        <TwitterIcon />
                      </button>

                      <IconButton
                        onClick={() => {
                          handleShare();
                          window.open("https://www.facebook.com/RAPAnalytical");
                        }}>
                        <FacebookIcon />
                      </IconButton>

                      <IconButton
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/rapanalytical/"
                          )
                        }>
                        <InstagramIcon />
                      </IconButton>

                      <IconButton
                        onClick={() =>
                          window.open(
                            "https://www.linkedin.com/in/rapanalytical/"
                          )
                        }>
                        <LinkedInIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>

                <h4>
                  <strong>
                    <Link
                      to={"/foundation-course-registration"}
                      state={{
                        data: {
                          title: "Medical Coding",
                          fee: "25,000",
                          sub_fee: "27,000",
                          paid_fee: "13,500",
                        },
                      }}>
                      Buy Now{" "}
                    </Link>
                  </strong>
                </h4>
              </div>

              <div className="course-img">
                <img src={CourseImg} alt="" />
              </div>
            </div>

            <div className="course-desc-content">
              <Tab.Container defaultActiveKey="overview">
                <Nav variant="pills" className="course-info-tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">
                      <h4>OverView</h4>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="course-desc">
                      <h3>
                        <strong>What is Medical Coding? </strong>
                      </h3>

                      <p>
                        Medical coding is the process of taking a patient’s
                        health care information like medical procedures,
                        diagnosis, necessary medical equipment, and medical
                        services information from the physician's notes. A
                        medical coder transforms this information into universal
                        medical alphanumeric codes.
                      </p>

                      <p>
                        There are three types of universally known medical
                        alphanumeric codes:
                      </p>
                      <ul>
                        <li>
                          <strong>
                            International Classification of Diseases (ICD):
                          </strong>
                          : The World Health Organization (WHO) created this
                          internationally used code. The classification system
                          is largely used for the purpose of health recording
                          and data collection, among other uses. These codes can
                          classify symptoms, diseases, illnesses, and causes of
                          death.
                        </li>
                        <li>
                          <strong>Current Procedural Terminology (CPT):</strong>
                          : CPT codes identify medical, surgical, and diagnostic
                          procedures and services within the US. The American
                          Medical Association (AMA) developed this system, which
                          includes three categories: procedures and contemporary
                          medical practices, clinical labs, and emerging
                          technologies.
                        </li>
                        <li>
                          <strong>
                            Healthcare Common Procedure Coding (HCPCS):
                          </strong>
                          The Centers for Medicare and Medicaid Services (CMS)
                          developed this coding system to help with processing
                          insurance claims for Medicare or other providers.
                          Coders use them to note medical procedures, products,
                          supplies, and services.
                        </li>
                      </ul>

                      <p>
                        Medical coding's primary use is to ensure medical
                        billing and insurance carriers pay and process claims
                        correctly, but the system is also valuable for research
                        purposes and basic medical record keeping for patients.
                      </p>

                      <p>
                        Each code tells insurance companies, researchers, and
                        health care providers the exact diagnosis, procedure,
                        and/or medical service provided. In short, these codes
                        act as a universal way to quantify health care visits
                        and make physician’s notes less abstract for insurance
                        carriers and future health providers.
                      </p>

                      <h3>Responsibilities of a medical coder</h3>

                      <p>
                        One of the core responsibilities of a medical coder is
                        to ensure the accuracy of the medical records you
                        transcribe and the codes you use. Other medical coder
                        job requirements include proficiency in a variety of
                        medical codes and adherence to the medical coding code
                        of ethics.
                      </p>

                      <h5>
                        Some of the day-to-day responsibilities of medical
                        coding jobs include:
                      </h5>

                      <ul>
                        <li>
                          Transcribing patient information into appropriate
                          medical codes
                        </li>
                        <li>
                          Examining medical documents and records for missing
                          information
                        </li>
                        <li>Conducting chart audits</li>
                        <li>
                          Collecting, researching, and filing patient medical
                          information
                        </li>
                        <li>
                          Ensuring all documents are grammatically correct
                        </li>
                        <li>
                          Complying with medical coding guidelines and policies
                        </li>
                      </ul>

                      <h3>How to become a medical coder</h3>
                      <p>
                        Getting started in medical coding requires the following
                        steps.
                      </p>
                      <ul>
                        <li>
                          1.Meet the basic prerequisites.
                          <p>
                            The basic prerequisites for getting a medical coding
                            job are to complete medical coding training. This
                            can be done in the form of an associate degree
                            program in medical coding, a certificate in medical
                            coding from an accredited organization, or
                            on-the-job in-house training by an employer. To be
                            eligible for training, you’ll need your high school
                            diploma or GED.
                          </p>
                        </li>

                        <li>
                          Complete medical coding training.
                          <p>
                            If you are choosing to earn your associate degree in
                            medical coding, look for a reputable program offered
                            at a community college or vocational school. There
                            are in-person and online options.
                          </p>
                          <p>
                            If you would rather earn a certificate in medical
                            coding, think about where you’d like to work. Then
                            try to gain professional experience in that
                            particular health care field, as many certificate
                            programs require some prior experience in medical
                            coding and/or an associate degree.
                          </p>
                        </li>
                        <li>
                          Obtain your medical coding certification.
                          <p>
                            You can obtain your medical coding certification
                            after completing an associate degree in medical
                            coding or with no degree at all. Entry-level
                            certifications can help you get a medical coding
                            job. You can obtain specialty medical coding
                            certifications at any point in your career as a
                            medical coder. A complete list of all medical coding
                            certifications is available through the three major
                            national accrediting organizations: PMI, AHIMA, or
                            AAPC. Most certification programs include a final
                            exam that you must pass to earn your certificate.
                          </p>
                        </li>
                        <li>
                          Brush up on medical coding skills.
                          <p>
                            Once completing training or obtaining medical coding
                            certification, take time to brush up on the top
                            in-demand skills you’ll need as a medical coder.{" "}
                          </p>
                        </li>
                        <li>
                          Look for a job.
                          <p>
                            When the time has come to find a job, ask yourself
                            what area of the health care industry you’d enjoy
                            and what you're qualified for. Is there a specialty
                            like pediatrics that interests you? Do you want to
                            apply for jobs in your area to get some professional
                            experience first? You may also be interested in a
                            remote medical coding job.
                          </p>
                        </li>
                      </ul>

                      <h3>How long does it take to become a medical coder?</h3>
                      <p>
                        It takes about two years to become a medical coder if
                        you choose to earn your associate degree. It may take
                        anywhere from nine to 20 months to become a medical
                        coder if you choose to enroll in a certification
                        program.
                      </p>

                      <h3>Where do medical coders work?</h3>
                      <p>
                        Medical coders may work in physicians’ offices or other
                        health care facilities. They may work in offices or
                        remotely. Here's a full list:
                      </p>

                      <p>
                        <strong>Consulting services:</strong>A medical coding
                        consultant may work in a variety of health care offices,
                        and they may travel or work remotely. Their job is to
                        check the accuracy of medical records. They may also
                        train other medical coders.
                      </p>

                      <p>
                        <strong>Government agencies:</strong>
                        The federal government is a major employer of medical
                        coders. They may employ medical coders for federal
                        agencies such as the Army National Guard, US Air Force,
                        and the Department of Veterans Affairs.
                      </p>

                      <p>
                        <strong> Hospitals and clinics:</strong>
                        Hospitals and clinics are among the most common places
                        for a medical coder to work. They typically have
                        multiple billing departments that require many medical
                        coders.
                      </p>

                      <p>
                        <strong>Insurance companies:</strong>
                        Insurance companies may hire medical coders to check for
                        the accuracy and verification of medical claims that
                        come in from health care providers. Medical coders
                        working in these environments should be quite proficient
                        in medical coding.
                      </p>

                      <p>
                        <strong>
                          Nursing homes and assisted living facilities:
                        </strong>
                        Nursing home medical coders work in assisted living
                        facilities, hospice care centers, residential nursing
                        homes, and other facilities that serve senior citizens.
                        They may work with different types of medical codes
                        specific to senior services.
                      </p>

                      <p>
                        <strong>Remote work:</strong>
                        Medical coding is a service some health care providers
                        outsource. Some medical coders are able to work
                        remotely. Requirements include proper training, access
                        to the internet, and a reliable computer. Some remote
                        medical coders are full- or part-time employees, while
                        others work as contractors or freelancers. Typically,
                        employers have HIPAA privacy and security requirements
                        since you'll be accessing people’s medical records.
                      </p>

                      <h3>Get started today</h3>
                      <p>
                        Medical coding is a highly accessible career with room
                        to grow. Enroll in a medical coding program and get your
                        certification to begin. Both online and in-person
                        programs are available. You might be interested in
                        Yale's Introduction to Medical Software to get
                        acquainted to software in the health care field.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum">
                    <div className="course-desc">
                      <div className="curriculum-content">
                        <h5>Modules</h5>

                        <ul>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Introduction to Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Why need to regulate
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Pharmaceutical drug regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Challenge to regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Product Life Cycle - Regulatory Affairs Perspective
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            The various roles within regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Reporting of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Organizational structure of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory strategy
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Major regulatory authority of different countries
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Exception from the regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Importance of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Responsibilities of regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Recent advancements in Drug Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="reviews">
                    <div className="course-desc">
                      <div className="review-content">
                        <p>Error: Query lp/v1/lazy-load/course-review</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Medical_Coding;
