import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";

import Layout from "../Layout/Layout";
import rapanalytical1 from "../assets/service-photos/rapanalytical1.png";
import rapanalytical2 from "../assets/service-photos/rapanalytical2.png";
import rapanalytical3 from "../assets/service-photos/rapanalytical3.png";
import rapanalytical4 from "../assets/service-photos/rapanalytical4.png";
import rapanalytical5 from "../assets/service-photos/rapanalytical5.png";
import rapanalytical6 from "../assets/service-photos/rapanalytical6.png";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";

const images = [
  rapanalytical1,
  rapanalytical2,
  rapanalytical3,
  rapanalytical4,
  rapanalytical5,
  rapanalytical6,
];
function Service() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <MetaTags>
        <title>Service | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/service"}>Service</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Service" />
        <div className="container">
          <div className="cards-group">
            <div className="single-card">
              <div className="single-card-desc">
                <h3>Research work for M. Pharm, M. Sc. or Ph.D.</h3>
                <p class="card-text">
                  <ul>
                    <li>Research hypothesis</li>
                    <li>Assistance for literature review</li>
                    <li>Project designing</li>
                    <li>Project/ research work delivery</li>
                    <li>E-write-up assistance</li>
                    <li>Technical analysis</li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="single-card">
              <div className="single-card-desc">
                <h3>
                  {" "}
                  Method Development, Validation & Forced Degradation Studies by
                  RP-HPLC
                </h3>
                <p class="card-text">
                  <ul>
                    <li>Method development</li>
                    <li>Validation by ICH Guidelines</li>
                    <li>Forced degradation studies</li>
                    <li>Prepared calculation chart</li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="single-card">
              <div className="single-card-desc">
                <h3>
                  {" "}
                  Method Development, Validation using QBD approach by RP-HPLC
                </h3>
                <p class="card-text">
                  <ul>
                    <li>Software designed trials</li>
                    <li>Validation by ICH Guidelines</li>
                    <li>ANOVA, 3D graphs & box diagrams</li>
                    <li>Variable Parameters</li>
                  </ul>
                </p>
              </div>
            </div>
            <div className="single-card">
              <div className="single-card-desc">
                <h3>
                  {" "}
                  Bioanalytical Method Development, Validation using human /
                  animal plasma using RP-HPLC
                </h3>
                <p class="card-text">
                  <ul>
                    <li>Method development & Validation</li>
                    <li>Pharmacokinetic studies</li>
                    <li>Stability Studies</li>
                    <li>Other biological studies</li>
                  </ul>
                </p>
              </div>
            </div>

            <div className="single-card">
              <div className="single-card-desc">
                <h3>Sample Testings Facility</h3>
                <p class="card-text">
                  <ul>
                    <li>High Performance Liquid Chromatography</li>
                    <li>Gas Chromatography</li>
                    <li>UV-VIS Spectrophotometer</li>
                    <li>LC-MS Chromatography</li>
                    <li>Infrared Spectroscopy</li>
                    <li>Dissolution Studies</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Service;
