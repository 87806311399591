import  { React } from "react";
import { MetaTags } from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
// Custom Component
import Layout from "../Layout/Layout";

// Captcha Site Key 6LcDArMlAAAAAGdUkaOSAMk78uOJMZqTNMf32LBo
// Captcha Secret Key 6LcDArMlAAAAALAN_z0s2WhuNOmYfNvTOk2EuSZf

function PaymentFail() {
 
  return (
    <>
      <>
      <MetaTags>
        <title>Course Registration | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>
      Course Registration Fail
      </BreadcrumbsItem>
      <Layout>
        <BreadCrumb name="OOPS" />

        <div className="container-fluid">
          <div className="container">
            <div className="form-wrapper">
            
                            <h3>
                                <strong> Course Registration Fail</strong>
                            </h3>
                            <div className="row">
                       <p> Oops something wrong! Please try again later !!!!!!</p>
                       
                            </div>

                     
            </div>
          </div>
        </div>


      </Layout>
      ;
    </>
    </>
  );
}

export default PaymentFail;
